export const SAML_URL = process.env.NEXT_PUBLIC_SAML_LOGIN_URL || "";
export const SERVICES_URL = process.env.NEXT_PUBLIC_SERVICES_URL || "";

export const RouteConstants = {
  WILDCARD: "*",
  BASE: "/",
  LOGIN: "/login",
  SEARCH: "/search",
  PROFILE: "/profile",
  POSITIONS: "/positions",
  TRACK: "/track",
  QUESTIONNAIRES: "/questionnaires",
  EVENTS: "/events",
  REPORTS: {
    BASE: "/reports",
    CANDIDATE_INFO: "/reports/candidateInfo",
    CANDIDATE_PROGRESS: "/reports/candidateProgress",
    CLUB_PERFORMANCE: "/reports/clubPerformance",
    GEOGRAPHICAL_INFO: "/reports/geographicalInfo"
  },
  NOTIFICATIONS: "/notifications",
  SETTINGS: {
    BASE: "/settings",
    INVITATIONS: "/settings/invitations",
    RECOMMENDATIONS: "/settings/recommendations",
    CANDIDATE_MANAGEMENT: "/settings/candidateManagement",
    SUPPORT: "/settings/support"
  }
};
