import { GenericResponseMessage, ResponseMessage } from "@/types/rest";
import { AxiosError } from "axios";
import { isEmpty } from "best-common-react";
import { ReadonlyURLSearchParams } from "next/navigation";

export const parseResponseMessage = (data: GenericResponseMessage) => {
  const result: ResponseMessage = {
    ...data,
    statusCode: parseInt(data.statusCode)
  };

  if ((result.statusCode >= 200 && result.statusCode < 300) || result.statusCode === 302) {
    return result;
  }

  throw new AxiosError(result.message, data.statusCode);
};

const convertValue = (value: object): string => {
  // TODO: handle dates & times

  // handle arrays
  if (Array.isArray(value)) {
    return value.map(convertValue).join(",");
  }

  // for anything else, just return the toString
  return value.toString();
};

/**
 * creates a query param string from an object
 * @param params params object
 * @returns string
 */
export const generateQueryParams = <T extends object>(params: T | ReadonlyURLSearchParams) => {
  if (params instanceof ReadonlyURLSearchParams) {
    // turn the params into an "entries" object.
    // NOTE: the values will always be strings, and they should already be
    // url encoded
    const entries: string[] = [];
    params.forEach((value, key) => {
      if (!isEmpty(value) && !/(null|undefined)/i.test(value)) {
        entries.push(`${key}=${value}`);
      }
    });

    return `?${entries.join("&")}`;
  }

  const res = Object.entries(params || {})
    .filter(([_, value]) => {
      return (
        value != null && (typeof value !== "string" || !isEmpty(value)) && (!Array.isArray(value) || !!value.length)
      );
    })
    .map(([key, value]) => `${key}=${encodeURIComponent(convertValue(value))}`)
    .join("&");
  return `?${res}`;
};
