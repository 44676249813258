import { CandidateStatus } from "@/types/candidate";
import { PositionStatus } from "@/types/position";
import { ValueOpt } from "best-common-react";

/**
 * Other option in dropdown fields
 * */
export const OTHER_LANGUAGE_ID: number = -1;
export const TWO_OR_MORE_RACE_ID: number = -1;
export const OTHER_GENDER_ID: number = -1;

export const YesOrNoOptions: ValueOpt<boolean>[] = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
];

export const candidateStatusOptions: ValueOpt<CandidateStatus>[] = [
  {
    label: "Active",
    value: "ACTIVE"
  },
  {
    label: "Inactive",
    value: "INACTIVE"
  }
];

export const positionStatusOptions: ValueOpt<PositionStatus>[] = [
  {
    label: "Active",
    value: "ACTIVE"
  },
  {
    label: "Closed",
    value: "CLOSED"
  }
];

export const NON_SELECTED: string = "None Selected";
