"use client";

import { GenericResponseMessage } from "@/types/rest";
import { UserDetails } from "@/types/user";
import { parseResponseMessage } from "@/utils/rest";
import { publicSelectApi, selectApi } from "./SelectApi";

export abstract class UserApi {
  /**
   * get the current user details
   * @returns User
   */
  static getCurrentUser = async () => {
    const results = await selectApi.get<UserDetails>("/user/details");
    return results.data;
  };

  static verifyEmail = async (email: string) => {
    const response = await selectApi.get<GenericResponseMessage>(
      `/verifyEmail?emailAddress=${encodeURIComponent(email)}`
    );
    return parseResponseMessage(response.data);
  };

  static loginWithCredentials = async (email: string, password: string) => {
    // first, verify the email address
    await UserApi.verifyEmail(email);

    // then, try and authenticate
    const form = new FormData();
    form.append("username", email);
    form.append("password", password);
    await selectApi.post("/candidate/authenticate", form);
  };

  static signOut = async () => {
    await selectApi.post<string>("/candidate/logout");
  };

  static forgotPassword = async (email: string) => {
    const response = await publicSelectApi.post<GenericResponseMessage>(`/public/forgot-password?emailid=${email}`);
    return parseResponseMessage(response.data);
  };
}
