"use client";

import { SERVICES_URL } from "@/constants";
import axios from "axios";

export const selectApi = axios.create({
  baseURL: `${SERVICES_URL}/api`,
  withCredentials: true
});

export const publicSelectApi = axios.create({
  baseURL: `${SERVICES_URL}/api`
});
