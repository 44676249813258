import { RefData, Skill } from "@/types/candidate";
import { ValueOpt } from "best-common-react";

export const createRefDataValueOpts = (refData: RefData[]) =>
  refData.map(ref => ({ value: ref.id, label: ref.displayName }) as ValueOpt<number>);

export const createSkillValueOpts = (skills: Skill[]) =>
  skills.map(ref => ({ value: ref.id, label: ref.skillName }) as ValueOpt<number>);

/**
 * Given array of values of selected options
 * return the list of ValueOpts selected
 * */
export const getValueOpts = <T>(options: ValueOpt<T>[], selected?: T[]) => {
  if (!selected) {
    return [];
  }

  const selectedOptions: ValueOpt<T>[] = [];
  selected.forEach(value => {
    const option = options.find(opt => opt.value === value);

    if (option) {
      selectedOptions.push(option);
    }
  });
  return selectedOptions;
};

/**
 * Given array of value opts of selected options
 * return the value opt in an array of their values
 * */
export const valueOptsToValue = <T>(options?: ValueOpt<T> | ValueOpt<T>[]) => {
  return Array.isArray(options) ? options?.map(v => v.value) || [] : options?.value;
};
