"use client";

import { UserApi } from "@/api/UserApi";
import { PageLoader } from "@/components/loading";
import { Action, ReactContext, ReactProps } from "@/types/core";
import { SelectRole, UserDetails } from "@/types/user";
import { usePathname, useRouter } from "next/navigation";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";

interface UserAction extends Action {
  loggedIn?: boolean;
  hasError?: boolean;
  userDetails?: UserDetails;
}

interface UserState extends ReactContext<UserAction> {
  initializing: boolean;
  hasError: boolean;
  loggedIn: boolean;
  userDetails: UserDetails | null;
  role: SelectRole | null;
  isBoc: boolean;
  isTeam: boolean;
  isCandidate: boolean;
  signOut: () => void;
}

const initialState: UserState = {
  dispatch: () => {},
  signOut: () => {},
  initializing: true,
  hasError: false,
  loggedIn: false,
  userDetails: null,
  role: null,
  isBoc: false,
  isTeam: false,
  isCandidate: false
};

export const UserContext = createContext(initialState);

const generateUserRoles = (user?: UserDetails | null): SelectRole | null => {
  if (!user) {
    return null;
  }

  const roles = new Set(user.samlRole || []);
  if (user.role === "OFFICE" || roles.has("select-central-office-employee")) {
    return "BOC";
  }

  if (user.role === "CANDIDATE" || roles.has("CANDIDATE")) {
    return "CANDIDATE";
  }

  if (user.role === "TEAM" || roles.has("select-team-employee")) {
    return "TEAM";
  }

  return null;
};

const reducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case "updateFields": {
      return {
        ...state,
        initializing: false,
        hasError: !!action.hasError,
        loggedIn: !!action.loggedIn,
        userDetails: action.userDetails || null,
        role: generateUserRoles(action.userDetails)
      };
    }
    case "setUserDetails": {
      if (!action.userDetails) {
        throw Error("must provide user details");
      }

      return {
        ...state,
        loggedIn: true,
        hasError: false,
        initializing: false,
        userDetails: action.userDetails,
        role: generateUserRoles(action.userDetails)
      };
    }
    default:
      return state;
  }
};

interface UserProviderProps extends ReactProps {
  publicPaths: string[];
}

export const UserProvider = ({ publicPaths = [], children }: UserProviderProps) => {
  // hooks
  const router = useRouter();
  const pathname = usePathname();
  const [state, dispatch] = useReducer(reducer, initialState);

  // constants
  const { initializing, loggedIn, hasError } = state;
  const isLoginPage = pathname.replace(/\/$/, "").includes("/login");
  const nonAuthPaths = useMemo(() => new Set(publicPaths.map(p => p.replace(/\/$/, ""))), [publicPaths]);

  // functions
  const getLoggedInUser = useCallback(async () => {
    try {
      const userDetails = await UserApi.getCurrentUser();
      dispatch({ type: "updateFields", loggedIn: true, userDetails });
    } catch (error) {
      console.error(error);
      dispatch({ type: "updateFields", hasError: true });
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await UserApi.signOut();
    } finally {
      dispatch({ type: "updateFields", hasError: true });
    }
  }, []);

  // effects
  // check if logged in
  // NOTE: the axios.interceptor is not used here
  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  // handle login page when logged in
  useEffect(() => {
    if (!initializing) {
      if (loggedIn && isLoginPage && !hasError) {
        router.push("/");
      } else if (!loggedIn && !isLoginPage && !nonAuthPaths.has(pathname.replace(/\/(index\.html)?$/i, ""))) {
        router.push("/login");
      }
    }
  }, [router, initializing, loggedIn, isLoginPage, hasError, nonAuthPaths, pathname]);

  // render
  // show loading screen
  if (initializing) {
    return <PageLoader />;
  }

  // return
  return (
    <UserContext.Provider
      value={{
        ...state,
        dispatch,
        signOut,
        isBoc: state.role === "BOC",
        isTeam: state.role === "TEAM",
        isCandidate: state.role === "CANDIDATE"
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};
