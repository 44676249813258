import { SkillCategoryConstants } from "@/constants/skills";
import { RefData, Region, Skill, SkillSubcategory } from "@/types/candidate";
import { Action, ReactContext } from "@/types/core";
import { Organization } from "@/types/organization";
import { createSkillValueOpts } from "@/utils/select";
import { ValueOpt } from "best-common-react";

export interface LookupState extends ReactContext<LookupAction> {
  loading: boolean;
  skills: Skill[];
  skillMap: { [id: number]: Skill };
  skillCategories: string[];
  skillSubcategories: SkillSubcategory[];
  skillSubcategoryMap: { [id: number]: SkillSubcategory };
  regions: Region[];
  regionMap: { [id: number]: Region };
  organizations: Organization[];
  organizationMap: { [id: number]: Organization };
  organizationOptions: ValueOpt<number>[];
  languages: RefData[];
  languageMap: { [id: number]: RefData };
  baseballOpsFilters: ValueOpt<number>[];
  baseballBusinessFilters: ValueOpt<number>[];
  programmingLanguageOpts: ValueOpt<number>[];
  baseballCentricOpts: ValueOpt<number>[];
  baseballBusinessOpts: ValueOpt<number>[];
  productivitySoftwareOpts: ValueOpt<number>[];
  regionFilters: ValueOpt<number>[];
  languageFilters: ValueOpt<number>[];
  veteranStatuses: RefData[];
  genderIdentities: RefData[];
  racialIdentities: RefData[];
  communicationMethods: RefData[];
  documentTypes: RefData[];
  closePositionReasons: RefData[];
}

export enum LookupActions {
  _SET_FILTERS = "setFilters",
  _SET_LOADING = "setLoading"
}

export interface LookupAction extends Action {
  loading?: boolean;
  skills?: Skill[];
  skillCategories?: string[];
  skillSubcategories?: SkillSubcategory[];
  regions?: Region[];
  organizations?: Organization[];
  languages?: RefData[];
  veteranStatuses?: RefData[];
  genderIdentities?: RefData[];
  racialIdentities?: RefData[];
  documentTypes?: RefData[];
  communicationMethods?: RefData[];
  closePositionReasons?: RefData[];
}

export const lookupReducer = (state: LookupState, action: LookupAction): LookupState => {
  switch (action.type) {
    case LookupActions._SET_FILTERS: {
      const {
        skills = [],
        skillCategories = [],
        regions = [],
        languages = [],
        organizations = [],
        veteranStatuses = [],
        genderIdentities = [],
        racialIdentities = [],
        documentTypes = [],
        skillSubcategories = [],
        communicationMethods = [],
        closePositionReasons = []
      } = action;

      return {
        ...state,
        skills,
        skillCategories,
        regions,
        languages,
        organizations,
        loading: false,
        veteranStatuses,
        genderIdentities,
        racialIdentities,
        skillSubcategories,
        communicationMethods,
        documentTypes,
        closePositionReasons,
        // maps
        skillMap: skills.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        regionMap: regions.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        languageMap: languages.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        organizationMap: organizations.reduce((pv, c) => ({ ...pv, [c.organizationId]: c }), {}),
        skillSubcategoryMap: skillSubcategories.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        // filters
        baseballOpsFilters: skillSubcategories
          .filter(s => s.categoryId === 1)
          .map(s => ({ label: s.name, value: s.id })),
        baseballBusinessFilters: skillSubcategories
          .filter(s => s.categoryId === 2)
          .map(s => ({ label: s.name, value: s.id })),
        regionFilters: regions.map(r => ({ label: r.displayName, value: r.id })),
        languageFilters: [...languages.map(r => ({ label: r.displayName, value: r.id }))],
        // TODO: on the backend, we need to refactor so that there is no difference between "filters" and "Opts".
        // especially because they are both of type "ValueOpt", which can be very confusing
        programmingLanguageOpts: createSkillValueOpts(
          skills.filter(s => s.categoryId === 2 && s.category === SkillCategoryConstants.PROGRAMMING_LANGUAGES)
        ),
        baseballCentricOpts: createSkillValueOpts(
          skills.filter(s => s.categoryId === 1 && s.category === SkillCategoryConstants.BASEBALL_CENTRIC_KNOWLEDGE)
        ),
        baseballBusinessOpts: createSkillValueOpts(
          skills.filter(s => s.categoryId === 2 && s.category === SkillCategoryConstants.BASEBALL_BUSINESS_SKILL)
        ),
        productivitySoftwareOpts: createSkillValueOpts(
          skills.filter(s => s.categoryId === 2 && s.category === SkillCategoryConstants.PRODUCTIVITY_SOFTWARE)
        ),
        organizationOptions: organizations.map(o => ({ label: o.organizationName, value: o.organizationId }))
      };
    }
    case LookupActions._SET_LOADING:
      return {
        ...state,
        loading: !!action.loading
      };
    default:
      return state;
  }
};
