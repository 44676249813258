"use client";

import { LookupApi } from "@/api/LookupApi";
import { OTHER_GENDER_ID, OTHER_LANGUAGE_ID, TWO_OR_MORE_RACE_ID } from "@/constants/form";
import { LookupActions, lookupReducer, LookupState } from "@/reducer/lookup";
import { RefData } from "@/types/candidate";
import { ReactProps } from "@/types/core";
import { usePathname } from "next/navigation";
import { createContext, useCallback, useContext, useEffect, useReducer } from "react";

const initialState: LookupState = {
  dispatch: () => {},
  loading: false,
  skills: [],
  skillMap: {},
  skillCategories: [],
  skillSubcategories: [],
  skillSubcategoryMap: {},
  regions: [],
  regionMap: {},
  organizations: [],
  organizationMap: {},
  organizationOptions: [],
  languages: [],
  languageMap: {},
  baseballOpsFilters: [],
  baseballBusinessFilters: [],
  programmingLanguageOpts: [],
  baseballCentricOpts: [],
  baseballBusinessOpts: [],
  productivitySoftwareOpts: [],
  regionFilters: [],
  languageFilters: [],
  veteranStatuses: [],
  genderIdentities: [],
  racialIdentities: [],
  communicationMethods: [],
  documentTypes: [],
  closePositionReasons: []
};

export const LookupContext = createContext(initialState);

export const LookupProvider = ({ children }: ReactProps) => {
  // hooks
  const pathname = usePathname();
  const [state, dispatch] = useReducer(lookupReducer, initialState);

  // constants
  const isNotLoginPage = !pathname.includes("/login");

  // functions
  const initializeCandidateLookups = useCallback(async () => {
    dispatch({ type: LookupActions._SET_LOADING, loading: true });
    try {
      const [
        skills,
        skillCategories,
        skillSubcategories,
        regions,
        languages,
        organizations,
        veteranStatuses,
        genderIdentities,
        racialIdentities,
        communicationMethods,
        documentTypes,
        closePositionReasons
      ] = await Promise.all([
        LookupApi.getSkills(),
        LookupApi.getSkillCategories(),
        LookupApi.getSkillSubcategories(),
        LookupApi.getRegions(),
        LookupApi.getLanguages(),
        LookupApi.getOrganizations(),
        LookupApi.getVeteranIdentities(),
        LookupApi.getGenderIdentities(),
        LookupApi.getRacialIdentities(),
        LookupApi.getCommunicationMethods(),
        LookupApi.getDocumentTypes(),
        LookupApi.getClosePositionReasons()
      ]);

      const enrichedLanguages = [
        ...languages,
        {
          id: OTHER_LANGUAGE_ID,
          displayName: "Other",
          refTermKey: "OTHER_LANGUAGE",
          sortOrder: languages.length + 1,
          description: "Other language"
        }
      ];

      const enrichedRacialIdentities: RefData[] = [
        ...racialIdentities,
        {
          id: TWO_OR_MORE_RACE_ID,
          displayName: "Two or more Races",
          refTermKey: "OTHER_RACE",
          sortOrder: racialIdentities.length + 1,
          description: "Two or More Races"
        }
      ];

      const enrichedGenderIdentities: RefData[] = [
        ...genderIdentities,
        {
          id: OTHER_GENDER_ID,
          displayName: "Other",
          refTermKey: "OTHER_GENDER",
          sortOrder: genderIdentities.length + 1,
          description: "Other"
        }
      ];

      dispatch({
        type: LookupActions._SET_FILTERS,
        skills,
        skillCategories,
        skillSubcategories,
        regions,
        languages: enrichedLanguages,
        organizations,
        veteranStatuses,
        genderIdentities: enrichedGenderIdentities,
        racialIdentities: enrichedRacialIdentities,
        communicationMethods,
        documentTypes,
        closePositionReasons
      });
    } finally {
      dispatch({ type: LookupActions._SET_LOADING, loading: false });
    }
  }, [dispatch]);

  // effects
  useEffect(() => {
    if (isNotLoginPage) {
      initializeCandidateLookups();
    }
  }, [initializeCandidateLookups, isNotLoginPage]);

  // return
  return <LookupContext.Provider value={{ ...state, dispatch }}>{children}</LookupContext.Provider>;
};

export const useLookup = () => {
  const lookupContext = useContext(LookupContext);
  if (lookupContext === undefined) {
    throw new Error("useLookup must be used within a LookupProvider");
  }
  return lookupContext;
};
