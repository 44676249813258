import { RefData, Skill, SkillSubcategory } from "@/types/candidate";
import { Organization } from "@/types/organization";
import { selectApi } from "./SelectApi";

export abstract class LookupApi {
  static getSkills = async () => {
    const results = await selectApi.get<{ skills: Skill[] }>("/v2/candidate/skills");
    return results.data.skills;
  };

  static getSkillCategories = async () => (await selectApi.get<string[]>("/v2/candidate/skillCategories")).data;

  static getSkillSubcategories = async () => {
    const results = await selectApi.get<{ subcategories: SkillSubcategory[] }>("/public/subcategories");
    return results.data.subcategories;
  };

  static getOrganizations = async () => {
    const results = await selectApi.get<{ organizations: Organization[] }>("/lookups/organizations");
    return results.data.organizations;
  };

  static getRegions = async () => this.#getRefData("GEOGRAPHIC_REGION");
  static getLanguages = async () => this.#getRefData("language");
  static getVeteranIdentities = async () => this.#getRefData("VETERAN_IDENTITY");
  static getGenderIdentities = async () => this.#getRefData("GENDER_IDENTITY");
  static getRacialIdentities = async () => this.#getRefData("RACIAL_IDENTITY");
  static getCommunicationMethods = async () => this.#getRefData("COMMUNICATION_METHOD");
  static getClosePositionReasons = async () => this.#getRefData("POSITION_REASON_TO_CLOSE");
  static getDocumentTypes = async () => this.#getRefData("DOCUMENT_TYPE");

  static #getRefData = async (key: string) => {
    const results = await selectApi.get<RefData[]>(`/public/refdata/${key}`);
    return results.data;
  };
}
